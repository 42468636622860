
import { ChannelsModule } from "@/store/modules/channels";
import MentionChannel from "@/components/markup/MentionChannel.vue";
import { voiceChannelModule } from "@/store/modules/voiceChannels";
import { computed, defineComponent } from "vue";
export default defineComponent({
  components: { MentionChannel },
  setup() {
    const channel = computed(() => {
      const channelId = voiceChannelModule.joinedChannelId as string;
      return ChannelsModule.channels[channelId];
    });

    const sharingScreen = computed(() => voiceChannelModule.videoStream);
    const streamingAudio = computed(() => voiceChannelModule.audioStream);

    const toggleMic = () => voiceChannelModule.toggleMic();
    const turnOffScreenshare = () => voiceChannelModule.removeVideoStream();
    const endCall = () => voiceChannelModule.leave();

    return {
      channel,
      sharingScreen,
      streamingAudio,
      toggleMic,
      turnOffScreenshare,
      endCall
    };
  },
});
